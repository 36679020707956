<template>
  <div class="content">
    <el-card>
      <h3 class="mb-4">{{ $t("Search") }}</h3>
      <el-row :gutter="10">
        <el-col :span="6">
          <base-input :label="$t('Department')">
            <select class="form-control" v-model="cur_dept_idn" @change="department_changed">
              <option
                :key="0"
                :value="0">
                {{ '--' + $t('Department') + '--' }}
              </option>
              <option
                v-for="dept in deptList"
                :key="dept.IDn"
                :value="dept.IDn">
                {{ is_english ? dept.Name1 : dept.Name2 }}
              </option>
            </select>
          </base-input>
        </el-col>
        <el-col :span="6">
          <base-input :label="$t('Category')">
            <select class="form-control" v-model="cur_cate_idn">
              <option 
                :key="0"
                :value="0">
                {{ '--' + $t('Category') + '--' }}
              </option>
              <option
                v-for="cate in cateList"
                :key="cate.IDn"
                :value="cate.IDn">
                {{ is_english ? cate.Name1 : cate.Name2 }}
              </option>
            </select>
          </base-input>
        </el-col>

        <el-col :span="6">
          <base-input :label="$t('From')">
            <flat-picker
              slot-scope="{ focus, blur }"
              @on-open="focus"
              @on-close="blur"
              :config="picker_config"
              class="form-control datepicker"
              v-model="cur_from_date_time"
              :placeholder="'--' + $t('From') + '--'">
            </flat-picker>
          </base-input>
        </el-col>

        <el-col :span="6">
          <base-input :label="$t('To')">
            <flat-picker
              slot-scope="{ focus, blur }"
              @on-open="focus"
              @on-close="blur"
              :config="picker_config"
              class="form-control datepicker"
              v-model="cur_to_date_time"
              :placeholder="'--' + $t('To') + '--'">
            </flat-picker>
          </base-input>
        </el-col>

        <el-col :span="6">
          <base-input :label="$t('Store')">
            <select class="form-control" v-model="cur_store_id">
              <option
                :key="0"
                :value="0">
                {{ '--' + $t('Store') + '--' }}
              </option>
              <option
                v-for="store in storeList"
                :key="store.LocationID"
                :value="store.LocationID">
                {{ store.LocationName }}
              </option>
            </select>
          </base-input>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="8" class="text-center">
          <el-button type="primary" title="Send a request to generate a report for download later, it usually take a 24 hours." @click="form_request">{{
            $t("Request")
          }}</el-button>
        </el-col>
        <el-col :span="8" class="text-center">
          <el-button type="primary" @click="form_search">{{
            $t("Search")
          }}</el-button>
        </el-col>
        <el-col :span="8" class="text-center">
          <xlsx-workbook>
            <xlsx-sheet
              :collection="sheet.data"
              v-for="sheet in sheets"
              :key="sheet.name"
              :sheet-name="sheet.name"
            />
            <xlsx-download :filename="getReportName('POSSalesReportByCategroy', 'xlsx')">
              <el-button type="primary">XLSX</el-button>
            </xlsx-download>
          </xlsx-workbook>
        </el-col>
      </el-row>
    </el-card>
    <el-card v-if="backrun_data">
      <b-row class="mt-4" v-for="bk in backrun_data" :key="bk.IDn">
        <b-col cols="2" v-if="bk.IsDone=='1'">
          <a :href="downloadbase + bk.DataFile" target="_blank" class="btn btn-primary">{{ $t('Download') }} <i class='far fa-arrow-alt-circle-down'></i></a>
        </b-col>
        <b-col cols="2" v-else>
          <!-- <b-button class="btn btn-second">{{ bk.RequireTime }} <i class='far fa-window-close' style='color:red;'></i></b-button> -->
          {{ bk.RequireTime }}
        </b-col>
        <b-col cols="10">
          {{ JSON.parse(bk.ParaData) }}
        </b-col>
      </b-row>
    </el-card>

    <el-card >
      <div v-bind:style="{ width: report_width }">
        <div v-if="list_data">
          <div v-bind:style="titleStyleObject">{{ $t('POS Sales Report By Category') }}</div>
          
          <el-row>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ $t('From') + '#:' }}</div></el-col>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ display_from_date_time ? display_from_date_time : "" }}</div></el-col>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ $t('To') + ':' }}</div></el-col>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ display_to_date_time ? display_to_date_time : "" }}</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ $t('Department') + ':' }}</div></el-col>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ display_dept_name ? display_dept_name : "" }}</div></el-col>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ $t('Category') + ':' }}</div></el-col>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ display_cate_name ? display_cate_name : "" }}</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ $t('Store') + ':' }}</div></el-col>
            <el-col :span="6"><div v-bind:style="infoStyleObject">{{ display_store_name ? display_store_name : "" }}</div></el-col>
          </el-row>




          <div v-for="(category, category_idn) in list_data.category_list" :key="category_idn">
            <div><h1>{{ (is_english ? category['Name1'] : category['Name2']) + ' ' + category['PLU'] + '' }}</h1></div>
            <div v-bind:style="{ marginTop: '10px', marginBottom: '10px', backgroundColor: 'lightGrey' }">
              <el-row>
                <!-- v-bind:style="{ backgroundColor: 'lightGrey' }" -->
                <el-col :span="2">
                  <div v-bind:style="headerStyleObject">{{ $t('PLU') }}</div>
                </el-col>
                <el-col :span="14">
                  <div v-bind:style="headerStyleObject">{{ $t('Name') }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="headerStyleObject">{{ $t('Ave Price') }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="headerStyleObject">{{ $t('Qty') }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="headerStyleObject">{{ $t('Sub') }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="headerStyleObject">{{ $t('Sub') + '%' }}</div>
                </el-col>
              </el-row>
            </div>
            <div v-if="category.Products" v-bind:style="{marginTop: '10px', marginBottom: '10px',}">
              <el-row v-for="(product, product_idn) in category.Products" :key="product_idn" v-bind:style="row_dashed">
                <el-col :span="2">
                  <div v-bind:style="nameStyleObject">{{ product.PLU }}</div>
                </el-col>
                <el-col :span="14">
                  <div v-bind:style="nameStyleObject">{{ is_english ? product.Name1 : product.Name2 }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="nameStyleObject">{{ Number(product.AvgPrice).toFixed(2) }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="nameStyleObject">{{ Number(product.Qty).toFixed(2) }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="nameStyleObject">{{ Number(product.Subtotal).toFixed(2) }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="nameStyleObject">{{ (Number(product.SubtotalPercentage) * 100).toFixed(2).toString() + '%' }}</div>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-row>
                <!-- v-bind:style="{ backgroundColor: 'lightGrey' }" -->
                <el-col :span="12">
                  <div v-bind:style="headerStyleObject"></div>
                </el-col>
                <el-col :span="6">
                  <div v-bind:style="headerStyleObject">{{ $t('Subtotal') }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="headerStyleObject">{{ Number(category.Qty).toFixed(2) }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="headerStyleObject">{{ Number(category.Subtotal).toFixed(2) }}</div>
                </el-col>
                <el-col :span="2">
                  <div v-bind:style="headerStyleObject"></div>
                </el-col>
              </el-row>
              
              <hr v-bind:style="hr_solid" />
            </div>
          </div>
          <div>
            <el-row>
              <!-- v-bind:style="{ backgroundColor: 'lightGrey' }" -->
              <el-col :span="12">
                <div v-bind:style="rftStyleObject"></div>
              </el-col>
              <el-col :span="6">
                <div v-bind:style="rftStyleObject">{{ $t('Summary') }}</div>
              </el-col>
              <el-col :span="2">
                <div v-bind:style="rftStyleObject">{{ Number(list_data.Qty).toFixed(2) }}</div>
              </el-col>
              <el-col :span="2">
                <div v-bind:style="rftStyleObject">{{ Number(list_data.Subtotal).toFixed(2) }}</div>
              </el-col>
              <el-col :span="2">
                <div v-bind:style="rftStyleObject"></div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else>
          {{ $t('No Records') }}
        </div>
      </div>
    </el-card>
    <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { Table, TableColumn, Button, Row, Col, Card, Container, Header } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Card.name]: Card,
    [Container.name]: Container,
    [Header.name]: Header,
    flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    VueElementLoading,
  },
  data() {
    return {
      picker_config: {
        allowInput: true,
        enableTime: true,
        noCalendar: false,
        dateFormat: 'Y-m-d H:i',
        // altFormat: 'Y-m-d',
        // maxDate: 'today',
        maxDate: 
          new Date().getFullYear() + 
          '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + 
          '-' + new Date().getDate().toString().padStart(2, '0') + 
          ' 23:59:59',
        // New Date();  YYYY-MM-DD HH:MM,
        time_24hr: true,
      },
      
      titleStyleObject: {
        textAlign: 'center',
        // textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '28px',
        minHeight: '80px',
      },
      infoStyleObject: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '20px',
        minHeight: '36px',
      },
      // infodStyleObject: {
      //   textAlign: 'right',
      //   fontWeight: '2px',
      //   paddingLeft:'30px',
      //   fontSize: '18px',
      // },
      // markStyleObject: {
      //   textAlign: 'left',
      //   fontWeight: 'bold',
      //   fontSize: '20px',
      // },
      headerStyleObject: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
        minHeight: '30px',
      },
      // titStyleObject: {
      //   textAlign: 'left',
      //   fontWeight: 'bold',
      //   fontSize: '18px',
      //   paddingLeft:'60px',
      // },
      // tidStyleObject: {
      //   textAlign: 'left',
      //   fontWeight: '2px',
      //   paddingLeft:'30px',
      //   fontSize: '18px',
      // },
      nameStyleObject: {
        textAlign: 'center',
        fontWeight: '2px',
        minHeight: '30px',
      },
      // tftStyleObject: {
      //   fontWeight: 'bold',
      // },
      rftStyleObject: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '20px',
        minHeight: '40px',
      },
      // rfdStyleObject: {
      //   fontWeight: '2px',
      //   fontSize: '19px',
      // },
           
      row_dashed: {
        borderTop: '1px dashed #bbb',
        borderBottom: '1px dashed #bbb',
      }, 
      // hr_dashed: {
      //   borderTop: '3px dashed #bbb',
      // },
      // hr_dotted: {
      //   borderTop: '3px dotted #bbb',
      // },
      hr_solid: {
        borderTop: '3px solid #bbb',
        marginTop: '16px',
        marginBottom: '16px',
      },
      // hr_rounded: {
      //   borderTop: '8px solid #bbb',
      //   borderRadius: '5px',
      // },

      showloading: false,
      export_url: '',

      token: "",
      user_id: 0,

      backrun_data: false,
      downloadbase: "",

      list_data: null,
      fullCateList: null,
      deptList: null,
      cateList: null,
      storeList: null,

      cur_dept_idn: 0,
      cur_cate_idn: 0,
      cur_store_id: 0,
      cur_from_date_time: null,
      cur_to_date_time: null,

      display_dept_name: '',
      display_cate_name: '',
      display_store_name: '',
      display_from_date_time: '',
      display_to_date_time: '',

      sheets: [
        { name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2], [], [1, 2, 2, 2]] },
      ],
      report_width: null,
    };
  },
  computed: {
    pdfBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
    is_english: function () {
      switch (this.$i18n.locale) {
        case 'en':
          return true;
        case 'cn':
          return false;
        case 'cn-t':
          return false;
        default:
          return true;

      }
    },
  },
  methods: {
    department_changed() {
      this.cur_cate_idn = 0;
      if (!this.cur_dept_idn) {
        this.cateList = this.fullCateList;
        return;
      }
      for (var i=0; i<this.deptList.length; i++) {
        if (this.cur_dept_idn == this.deptList[i].IDn) {
          // find it
          this.cateList = this.deptList[i].category_list;
          break;
        }
      }
    },
    fillup_sheet() {
      // Init 2 dimensional array
      var dataarr = [];
      for (var i = 0; i < 10; i++) {
        dataarr[i] = [];
      }

      try {
        // Date line
        dataarr[0][0] = this.display_from_date_time + " To " + this.display_to_date_time;
        dataarr[1][0] = this.display_dept_name?" Department:" + this.display_dept_name:"";
        dataarr[1][0] += this.display_cate_name?" Category:" + this.display_cate_name:"";
        dataarr[1][0] += this.display_store_name?" Store:" + this.display_store_name:"";
        
        var y = 3;

        // Category Loop
        for (var categorykey in this.list_data.category_list) {
          if (this.list_data.category_list.hasOwnProperty(categorykey)) {
            if (this.list_data.category_list[categorykey].hasOwnProperty("Products")) {
              let productlist = this.list_data.category_list[categorykey]["Products"];
              dataarr[y][0] = (this.is_english?this.list_data.category_list[categorykey]["Name1"]:this.list_data.category_list[categorykey]["Name2"]) + this.list_data.category_list[categorykey]["PLU"];
              y++; if (dataarr[y] == undefined) dataarr[y] = [];
              // Product Title (hardcode)
              dataarr[y][0] = this.$t('PLU');
              dataarr[y][1] = this.$t('Name');
              dataarr[y][2] = this.$t('Ave Price');
              dataarr[y][3] = this.$t('Qty');
              dataarr[y][4] = this.$t('Sub');
              dataarr[y][5] = this.$t('Sub') + '%';
              y++; if (dataarr[y] == undefined) dataarr[y] = [];

              for (var productkey in productlist) {
                if (productlist.hasOwnProperty(productkey)) {
                  dataarr[y][0] = productlist[productkey].PLU;
                  dataarr[y][1] = this.is_english?productlist[productkey].Name1:productlist[productkey].Name2;
                  dataarr[y][2] = Number(productlist[productkey].AvgPrice).toFixed(2);
                  dataarr[y][3] = Number(productlist[productkey].Qty).toFixed(2);
                  dataarr[y][4] = Number(productlist[productkey].Subtotal).toFixed(2);
                  dataarr[y][5] = (Number(productlist[productkey].SubtotalPercentage) * 100).toFixed(2).toString() + '%';
                  y++; if (dataarr[y] == undefined) dataarr[y] = [];
                }
              }
              dataarr[y][0] = this.$t('Subtotal');
              dataarr[y][1] = "";
              dataarr[y][2] = "";
              dataarr[y][3] = Number(this.list_data.category_list[categorykey]["Qty"]).toFixed(2);
              dataarr[y][4] = Number(this.list_data.category_list[categorykey]["Subtotal"]).toFixed(2);
              dataarr[y][5] = "";
              y++; if (dataarr[y] == undefined) dataarr[y] = [];
            }
            y++; if (dataarr[y] == undefined) dataarr[y] = [];
          }
        }
        dataarr[y][0] = this.$t('Summary');
        dataarr[y][1] = "";
        dataarr[y][2] = "";
        dataarr[y][3] = Number(this.list_data.Qty).toFixed(2);
        dataarr[y][4] = Number(this.list_data.Subtotal).toFixed(2);
        dataarr[y][5] = "";
      } catch (err) {
        // console.log("fillup_sheet err",err);
      }
      // console.log("fillup_sheet",x,y,dataarr);
      var sheetname = "PosSalesReportByCategory";
      this.sheets = [{ name: sheetname, data: dataarr }];
    },
    remove_dashColons_replace_space_with_underscore(myString) {
      if (myString == undefined) {
        return '';
      }
      var newString = myString.replace(/[-:]/g, "");
      newString = newString.replace(" ", "_");
      return newString;
    },
    getReportName(reportName , reportType) {
      let start = this.remove_dashColons_replace_space_with_underscore(this.display_from_date_time);
      let end = this.remove_dashColons_replace_space_with_underscore(this.display_to_date_time);
      return reportName + '_' + start + '_' + end + '.' + reportType;
    },
    async get_list_data() {
      this.showloading = true;
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var url = "";
      if (axios.defaults.baseURL != undefined) {
        url = axios.defaults.baseURL;
      }
      // url += "/Api/Web/Pos_transaction_report/pdf?";
      url += "/Api/Web/Pos_sales_report_by_category/pdf?";
      url += "token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&FromDateTime=" + this.cur_from_date_time;
      url += "&ToDateTime=" + this.cur_to_date_time;
      url += "&lang=" + this.$i18n.locale;

      bodyFormData.append("FromDateTime", this.cur_from_date_time);
      bodyFormData.append("ToDateTime", this.cur_to_date_time);
      bodyFormData.append("lang", this.$i18n.locale);

      if (this.cur_dept_idn) {
        bodyFormData.append("DepartmentIDn", this.cur_dept_idn);
        url += "&DepartmentIDn=" + this.cur_dept_idn;
      }
      if (this.cur_cate_idn) {
        bodyFormData.append("CategoryIDn", this.cur_cate_idn);
        url += "&CategoryIDn=" + this.cur_cate_idn;
      }
      if (this.cur_store_id) {
        bodyFormData.append("StoreID", this.cur_store_id);
        url += "&StoreID=" + this.cur_store_id;
      }

      this.export_url = url;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_sales_report_by_category",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storeList = rt.data.store_list;
              if (!that.deptList) that.deptList = rt.data.department_list;
              if (!that.cateList) that.cateList = rt.data.category_list;
              that.fullCateList = rt.data.category_list;
              
              that.list_data = rt.data.list_data;
              that.display_dept_name = rt.data.display_department_name;
              that.display_cate_name = rt.data.display_category_name;
              that.display_store_name = rt.data.display_store_name;
              that.display_from_date_time = rt.data.display_from_date_time;
              that.display_to_date_time = rt.data.display_to_date_time;
              that.showloading = false;
              if (Array.isArray(rt.data.backrun_data) && (rt.data.backrun_data.length > 0)) {
                that.backrun_data = rt.data.backrun_data;
              } else {
                that.backrun_data = false;
              }
              if (that.list_data) {
                console.log("Pos_sales_report_by_category ====>>> ", JSON.parse(JSON.stringify(that.list_data)));
                that.fillup_sheet();
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            alert(response.status);
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          alert(error);
          that.$router.push("/login");
        });
    },
    async form_request() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("RunType", "pos_sales_report_by_category");
      var para = {
        FromDateTime: this.cur_from_date_time,
        ToDateTime: this.cur_to_date_time,
        lang: this.$i18n.locale,
      };
      bodyFormData.append("FromDateTime", this.cur_from_date_time);
      bodyFormData.append("ToDateTime", this.cur_to_date_time);
      bodyFormData.append("lang", this.$i18n.locale);
      if (this.cur_dept_idn) {
        para["DepartmentIDn"] = this.cur_dept_idn;
      }
      if (this.cur_cate_idn) {
        para["CategoryIDn"] = this.cur_cate_idn;
      }
      if (this.cur_store_id) {
        para["StoreID"] = this.cur_store_id;
      }
      bodyFormData.append("ParaData", JSON.stringify(para));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_sales_report_by_category/back_run",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (Array.isArray(rt.data.backrun_data) && (rt.data.backrun_data.length > 0)) {
                that.backrun_data = rt.data.backrun_data;
              } else {
                that.backrun_data = false;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            } else {
              alert(rt);
            }
          }
          else {
            alert(response.status);
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_search() {
      this.get_list_data();
    },
    get_today(flag = '') {
      var today = new Date();
      // console.log('today', today);
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var HH = today.getHours();
      var min = today.getMinutes();
      if (flag == 'start'){
        HH = '00';
        min = '00';
      }
      else if (flag == 'end'){
        HH = '23';
        min = '59';
      }
      return yyyy + '-' + mm + '-' + dd + ' ' + HH + ':' + min;
    },
    get_date(day, flag = '') {
      // console.log('day', day);
      var dd = String(day.getDate()).padStart(2, '0');
      var mm = String(day.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = day.getFullYear();
      var HH = day.getHours();
      var min = day.getMinutes();
      if (flag == 'start'){
        HH = '00';
        min = '00';
      }
      else if (flag == 'end'){
        HH = '23';
        min = '59';
      }
      // console.log('convert-day', yyyy + '-' + mm + '-' + dd);
      return yyyy + '-' + mm + '-' + dd + ' ' + HH + ':' + min;
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.cur_from_date_time = this.get_today('start');
      this.cur_to_date_time = this.get_today('end');
      // console.log('datetime:', this.cur_from_date_time, this.cur_to_date_time);
      // console.log('datetime:', new Date());
      // console.log('datetime:', new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 23:59:59');
      this.downloadbase = "";
      if (axios.defaults.baseURL != undefined) {
        this.downloadbase = axios.defaults.baseURL;
      }
      this.downloadbase += "/Api/backrun/download/"; 
      this.get_list_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
  .content {
    padding-top: 40px;
  }
</style>
